import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ChevronDown from 'icons/chevron_down.svg';

const isActive = ({ isCurrent }) => {
  return isCurrent
    ? {
        className:
          'bukazu-p-medium bukazu-p-medium--semi-bold nav-link dropdown-toggle pl-0 pr-2 py-4 active',
      }
    : null;
};

const NavItems = ({ items }) => {
  items = JSON.parse(items);

  function sub_menu(items) {
    if (items.length > 0) {
      return (
        <ul className="sub-menu">
          {items.map(function(item) {
            return (
              <li key={item.id}>
                <Link to={item.url} getProps={isActive}>
                  <span className="link">{item.title}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      );
    }
  }

  const navs = items.menu_items.map(function(item) {
    let val;

    let subs = items.menu_items.filter(x => x.parent === item.id);
    if (subs.length > 0) {
      val = (
        <li key={item.title} className="parent">
          <div className="parent-link">
            {item.title}
            <ChevronDown height="12px" width="12px" fill="#55504b" />
          </div>
          {sub_menu(subs)}
        </li>
      );
    } else {
      val = (
        <li
          className="nav-item position-unset mr-3 d-flex align-items-center"
          key={item.title}
        >
          <Link
            to={item.url}
            // getProps={isActive}
            activeClassName="active"
            className="bukazu-p-medium bukazu-p-medium--semi-bold nav-link dropdown-toggle pl-0 pr-2 py-4"
          >
            <span className="link">{item.title}</span>
          </Link>
        </li>
      )
    }

    if (item.parent) {
      return '';
    } else {
      return val;
    }
  });

  return <ul className="navbar-nav mr-auto">{navs}</ul>;
};

NavItems.propTypes = {
  items: PropTypes.string.isRequired,
};

export default NavItems;
