import React from 'react'
import { Link } from 'gatsby'
import posed from 'react-pose'
import PropTypes from 'prop-types'
import Nav from './nav/nav'
import MobileNav from './nav/MobileNav'
import Logo from 'images/new_logo.svg'
// import Nav from 'components/header/nav';
// import CompleteNav from 'components/header/nav/completeNav';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
})

let lastScrollY = 0
let newScrollY = 0

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.activateMenu = this.activateMenu.bind(this)
    this.state = {
      menuVisible: false,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  nav = React.createRef()
  menu = React.createRef()
  menuToggle = React.createRef()

  handleScroll = () => {
    newScrollY = window.scrollY
    if (this.nav.current) {
      if (newScrollY > 100) {
        this.nav.current.classList.add('minimized')
        if (newScrollY > lastScrollY) {
          this.nav.current.classList.add('nav-up')
          this.nav.current.classList.remove('nav-down')
        }
        if (lastScrollY > newScrollY) {
          this.nav.current.classList.add('nav-down')
          this.nav.current.classList.remove('nav-up')
        }
      } else {
        this.nav.current.classList.remove('minimized')
      }
    }

    lastScrollY = newScrollY
  }

  activateMenu() {
    const { menuVisible } = this.state

    if (!menuVisible) {
      this.menu.current.classList.add('show')
      this.menuToggle.current.classList.add('is-active', 'open')
      this.setState({ menuVisible: true })
    } else {
      this.menu.current.classList.remove('show')
      this.menuToggle.current.classList.remove('is-active', 'open')
      this.setState({ menuVisible: false })
    }
  }

  render() {
    return (
      <AnimatedContainer>
        <div className="mobile-menu-off">
          <nav className="navbar navbar-desktop navbar-expand-lg navbar-light sticky-top border-bottom">
            <div className="container">
              <Link to="/" className="navbar-brand pt-0 bukazu_logo">
                <Logo />
              </Link>
              <div
                className="collapse navbar-collapse pt-0"
                id="navbarSupportedContent"
              >
                <Nav locale="nl" />
                <ul className="navbar-nav ml-auto">
                  <li className="ml-sm-3 mb-2 mb-sm-0 d-flex align-items-center">
                    <a
                      className="btn btn-outline-secondary login_button"
                      href="https://app.bukazu.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Login
                    </a>
                  </li>
                  <li className="ml-sm-3 mb-2 mb-sm-0 d-flex align-items-center">
                    <Link
                      className="btn btn-primary __register_ register-menu"
                      to="/registreer"
                    >
                      Meld je aan
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <div className="mobile-menu-on">
          <nav className="mobile-nav navbar fixed-top navbar-light bg-white py-3 px-0">
            <div className="d-flex justify-content-between px-3 w-100">
              <a href="/" className="navbar-brand py-2">
                <Logo width="150px" />
              </a>
              <button
                className="appearance-none hamburger pr-0"
                type="button"
                id="nav-mobile-icon"
                ref={this.menuToggle}
                onClick={this.activateMenu}
              >
                <span className="line" />
                <span className="line" />
                <span className="line" />
              </button>
            </div>
            <div className="navbar-collapse collapse" ref={this.menu}>
              <MobileNav locale={'nl'} />
            </div>
          </nav>
        </div>
      </AnimatedContainer>
    )
  }
}

Header.propTypes = {
  logo: PropTypes.array,
  locale: PropTypes.string,
}

Header.defaultProps = {
  locale: 'nl',
}

export default Header
