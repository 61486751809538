import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';

const isActive = ({ isCurrent }) => {
  return isCurrent
    ? {
        className:
          'bukazu-mobile-nav-button mobile-nav-button d-flex align-items-center w-100 py-3 my-2 px-3 border-0 collapsed active',
      }
    : null;
};

const Nav = ({ locale }) => (
  <StaticQuery
    query={graphql`
      query MobileNavQuery {
        allBuroBorkMenu(filter: { name: { eq: "Primary" } }) {
          edges {
            node {
              id
              field
              name
              locale
            }
          }
        }
      }
    `}
    render={data => {
      let menu = data.allBuroBorkMenu.edges.find(
        edge => edge.node.locale === locale
      );

      const mnnu = JSON.parse(menu.node.field);

      const navs = mnnu.menu_items.map(function(item) {
        let val;

        let subs = mnnu.menu_items.filter(x => x.parent === item.id);
        if (subs.length > 0) {
          val = (
            <li key={item.title} className="category-item">
              <div className="parent-link">{item.title}</div>
            </li>
          );
        } else {
          val = (
            <li className="category-item" key={item.title}>
              <Link
                to={item.url}
                getProps={isActive}
                className="bukazu-mobile-nav-button mobile-nav-button d-flex align-items-center w-100 py-3 my-2 px-3 border-0 collapsed"
              >
                <span className="link">{item.title}</span>
              </Link>
            </li>
          );
        }

        if (item.parent) {
          return '';
        } else {
          return val;
        }
      });

      return <ul className="list-unstyled category-item-list mt-4">{navs}</ul>;
    }}
  />
);

Nav.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default Nav;
