import { graphql } from 'gatsby'
import React, { useRef } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import Header from 'components/header'

import './index.scss'

const CustomHelmet = () => (
  <Helmet
    // title="Gatsby for sNyung"
    // meta={[
    //   { name: 'description', content: 'snyung theme' },
    //   { name: 'keywords', content: 'snyung, blog, theme' },
    //   {
    //     name: 'viewport',
    //     content: 'width=device-width, initial-scale=1',
    //   },
    // ]}
  >
    <html lang="nl" />
  </Helmet>
)

const Layout = props => {
  const { location = '/', children } = props
  const pathSplit = location.pathname.split('/')
  const bodyElm = useRef()

  return (
    <div className="main" ref={bodyElm}>
      {/* Common Helmet*/}
      <CustomHelmet />

      {/* Common Header */}

      {/* Name Card */}
      <div className="main-container">{children}</div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
