// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-boekingssysteem-js": () => import("./../src/pages/boekingssysteem.js" /* webpackChunkName: "component---src-pages-boekingssysteem-js" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-klantcases-js": () => import("./../src/pages/klantcases.js" /* webpackChunkName: "component---src-pages-klantcases-js" */),
  "component---src-pages-prijzen-js": () => import("./../src/pages/prijzen.js" /* webpackChunkName: "component---src-pages-prijzen-js" */),
  "component---src-pages-registreer-js": () => import("./../src/pages/registreer.js" /* webpackChunkName: "component---src-pages-registreer-js" */),
  "component---src-template-blog-js": () => import("./../src/template/blog.js" /* webpackChunkName: "component---src-template-blog-js" */),
  "component---src-template-case-js": () => import("./../src/template/case.js" /* webpackChunkName: "component---src-template-case-js" */),
  "component---src-template-page-js": () => import("./../src/template/page.js" /* webpackChunkName: "component---src-template-page-js" */)
}

